import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
//import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <GatsbyImage
          image={imageNodes[0].node.childImageSharp.gatsbyImageData}
          objectPosition="50% 50%"
          layout="constrained"
          alt=""
        />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        Wonder Lab Nishikadomaの技術内覧会用に製作されたエントランス映像。映像は全てナレーションを排したノンバーバル且つモノトーンで構成。パナソニックのロボティクス・チームとの協業により、パナソニックの技術の象徴である照明型ロボットと本映像とが、シンクロした動きで全編展開。技術の「種」が集まって暮らしを豊かにしていく様子を描いたインスタレーション。
      </p>
    );
  };

  const summaryData = {
    scopes: ['All'],
    awards: ['映文連アワード2016 優秀作品賞（準グランプリ）'],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(2, 7)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/nishikadoma"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/nishikadoma.*"}) {
      publicURL
    }
  }
`;
